<template>
    <div>
        <div class="input-wrapper" :class="{ 'form-floating': floating }">
            <input
                class="form-control input-grey"
                v-model="inputValue"
                :autofocus="focusPassword"
                :name="name"
                :type="passwordFieldType"
                :placeholder="placeholder"
                :autocomplete="autocomplete"
                :disabled="disabled"
                @input="onInput"
                @blur="handleBlur"
            />
            <label for="floatingPassword" v-if="floating">{{
                placeholder
            }}</label>
            <basic-button
                class="show-password"
                color="orange"
                @click="togglePasswordVisibility"
            >
                <fai
                    v-if="passwordFieldType === 'password'"
                    :icon="['far', 'eye']"
                ></fai>
                <fai v-else :icon="['far', 'eye-slash']"></fai>
            </basic-button>
        </div>
        <span
            class="error-message"
            v-show="
                (errorMessage || meta.valid || errorContent) && needErrorMessage
            "
        >
            {{ errorMessage || errorContent }}
        </span>
    </div>
</template>

<script>
import { useField } from "vee-validate"
import BasicButton from "@/components/basic/BasicButton.vue"

export default {
    name: "PasswordInput",
    components: {
        BasicButton,
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        rules: {
            type: Function,
        },
        focusPassword: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        autocomplete: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        floating: {
            type: Boolean,
            default: false,
        },
        needErrorMessage: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const {
            value: inputValue,
            errorMessage,
            handleChange,
            handleBlur,
            meta,
        } = useField(props.name, props.rules, {
            initialValue: props.modelValue,
        })

        const onInput = (event) => {
            handleChange(event)
            emit("update:modelValue", event.target.value)
            emit("errorMessage", errorMessage)
        }

        return {
            onInput,
            handleChange,
            handleBlur,
            errorMessage,
            inputValue,
            meta,
        }
    },

    data() {
        return {
            passwordFieldType: "password",
        }
    },
    methods: {
        togglePasswordVisibility() {
            this.passwordFieldType =
                this.passwordFieldType === "password" ? "text" : "password"
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.form-control {
    border-radius: 10px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-family: $font_avenir_book;
    font-size: $normal;
}

.input-grey {
    background-color: $light-grey;
    border-color: $light-grey;
}

.input-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    input {
        width: 100%;
        border-radius: 10px;
        color: $blue-neutral;

        &:focus {
            outline: none;
            box-shadow: inset 0 0 0 0.25rem rgba($blue-medium-pale, $alpha: 1);
            transition: all 200ms;
        }
    }

    .show-password {
        position: absolute;
        height: 100%;
        right: 0;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $orange-neutral;
        border-radius: 0 10px 10px 0px;
        cursor: pointer;

        svg {
            color: $white;
        }
    }
}
.error-message {
    color: $orange;
    font-family: $font_avenir_book;
    font-size: $small;
}

.form-floating {
    & > label {
        display: flex;
        justify-content: start;
        align-items: center;
        transform-origin: top left !important;
    }
    .form-control-plaintext ~ label,
    .form-control:focus ~ label,
    .form-control:not(:placeholder-shown) ~ label,
    .form-select ~ label {
        color: $blue-neutral;

        &::after {
            background-color: transparent;
        }
    }
}
</style>
